import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import settings from '~/settings';

import MirroredFlag from '../MirroredFlag';
import { ManagerPopUp } from './ManagerPopUp';
import { useStyles } from './styles';
import { Box, Typography, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

export const EnvironmentItem = ({ environment, removeEnvironmentById }) => {
  const classes = useStyles();

  const { thumbnails, name, description, id, is_mirror, can_manage } =
    environment || {};

  const image =
    thumbnails && thumbnails.length > 2 && thumbnails[2].href
      ? thumbnails[2].href
      : null;

  return (
    <Link to={`/dashboard/environments/${id}`} className={classes.root}>
      <Box
        className={classes.image}
        style={{
          backgroundImage: `url(${image && settings.baseUrl + image})`
        }}
      >
        <Box display={{ xs: 'none', sm: 'flex' }} className={classes.header}>
          <Box>
            {is_mirror && can_manage && <MirroredFlag type={'environment'} />}
          </Box>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <ManagerPopUp
              environment={environment}
              removeEnvironmentById={removeEnvironmentById}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box display={{ xs: 'flex', sm: 'none' }} className={classes.header}>
          <Box>
            {is_mirror && can_manage && <MirroredFlag type={'environment'} />}
          </Box>
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <ManagerPopUp
              environment={environment}
              removeEnvironmentById={removeEnvironmentById}
            />
          </Box>
        </Box>
        <Box style={{ overflow: 'hidden' }}>
          <Tooltip
            title={name}
            arrow
            placement="bottom"
            classes={{ tooltip: classes.customTooltip }}
          >
            <Typography className={classes.name}>{name}</Typography>
          </Tooltip>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>
    </Link>
  );
};

EnvironmentItem.propTypes = {
  environment: PropTypes.shape({
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string
      })
    ),
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_mirror: PropTypes.bool,
    can_manage: PropTypes.bool
  }),
  removeEnvironmentById: PropTypes.func.isRequired
};
